import React, { useEffect, useState  } from 'react';
//import { graphql } from "gatsby"
import { Link } from "gatsby"

import Layout from "components/Layout";
import Seo from 'components/Seo';
import Address from 'components/form/Address';
import Suggest from 'components/form/Suggest';
import { StaticImage } from "gatsby-plugin-image"



const Reg = ({location, data}) => {
  const [openMap, setopenMap] = useState(false);
  const [fromAddress, setFrom] = useState('');

  return (
    <Layout openModal={openMap}>
      <Seo
        title={'Digital Transit Solutions'}
        pathname={location.pathname}
      />
      {/*<section>
        <div  className="container">

        </div>
      </section>*/}

      <section>
        <div  className="cont">
          {/*<h1>Digital Transit Solutions</h1>*/}
          <h1>اخبرنا عن طريقك</h1>

          <div className="comboAddress">
            <Suggest />
            <Suggest />
            <div className="connector" />
          </div>



          <Address title="depart" titleAr="مكان الصعود"/>

          <Address title="distint" titleAr="مكان النزول"/>

<br />
          <h2>أوقات الرحلة</h2>

          <label htmlFor="appt">
          موعد الذهاب
            <input type="time" id="appt" name="appt" step="900" />
          </label>
          <label htmlFor="appt">
        موعد الإياب
            <input type="time" id="appt" name="appt"   />
          </label>

          <fieldset>
            <legend>أيام الأسبوع</legend>
            <br />
            <label htmlFor="switch">
              <input type="checkbox" id="switch" name="switch" role="switch" defaultChecked />
              السبت
            </label>
            <label htmlFor="switch">
              <input type="checkbox" id="switch" name="switch" role="switch" defaultChecked />
              الاحد
            </label>
            <label htmlFor="switch">
              <input type="checkbox" id="switch" name="switch" role="switch" defaultChecked />
        الإثنين
            </label>
            <label htmlFor="switch">
              <input type="checkbox" id="switch" name="switch" role="switch" defaultChecked />
            الثلاثاء
            </label>
            <label htmlFor="switch">
              <input type="checkbox" id="switch" name="switch" role="switch" defaultChecked />
              الأربعاء
            </label>
            <label htmlFor="switch">
              <input type="checkbox" id="switch" name="switch" role="switch" defaultChecked />
              الخميس
            </label>
            <label htmlFor="switch">
              <input type="checkbox" id="switch" name="switch" role="switch" />
            الجمعة
            </label>
          </fieldset>
<br />
        <h2>معلومات الإتصال</h2>
        <label htmlFor="firstname">
          الاسم
          <input type="text" id="firstname" name="firstname" placeholder="الاسم" minLength="3" maxLength="30" required />
        </label>


          <label htmlFor="mobile">
          رقم الجوال
            <input type="text" inputMode="numeric" id="mobile" name="mobile" placeholder="09xx xxx xxx" pattern="[0-9]+" minLength="10" maxLength="10" required />
          </label>


          <label htmlFor="email">البريد الإلكتروني</label>
          <input type="email" id="email" name="email" placeholder="البريد الإلكتروني" required />
          <small>نحن ملتزمون بحفظ بياناتك.</small>




          <fieldset>
            <label htmlFor="terms">
              <input type="checkbox" id="terms" name="terms" />
               الموافقة على الشروط والاحكام.
            </label>
          </fieldset>


          <button type="submit" className="secondary">ارسال</button>
          {/*<button type="button" className="contrast">ارسال</button>*/}



        </div>
      </section>
    </Layout>
  );
}

export default Reg
